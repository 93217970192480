<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="News" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="News page title *"
                  v-model="title"
                  :messages="['The recommended number of characters is 70']"
                  :error="!!errors.newspage_title"
                  :error-messages="errors.newspage_title"
                  @input="delete errors.newspage_title"
                />
                <va-input
                  label="News item link label *"
                  v-model="linkLabel"
                  :messages="['he recommended number of characters is 15']"
                  :error="!!errors.newspage_item_link_label"
                  :error-messages="errors.newspage_item_link_label"
                  @input="delete errors.newspage_item_link_label"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  name: 'grid',
  data () {
    return {
      title: '',
      linkLabel: '',

      errors: [],

      tabTitles: ['Main'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    putData () {
      return {
        newspage_title: this.title,
        newspage_item_link_label: this.linkLabel,
      }
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    submit () {
      axios.put(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/11`, this.putData)
        .then(response => {
          this.showToast('Successful update')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          this.showToast('Error')
        })
    },
    validate () {
      axios.put(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/validate/11`, this.putData, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
        .then(response => {
          this.errors = response.data
          if (!Object.keys(this.errors).length) {
            this.submit()
          }
        })
        .catch(error => {
          console.log(error)
          this.showToast('Validate Fetch Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/11`)
        .then(response => {
          // console.log(response.data)
          this.title = response.data.newspage_title
          this.linkLabel = response.data.newspage_item_link_label
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
